import axios from 'axios'

axios.defaults.headers.common['authorization'] = "eyJhbGciOiJIUzI1NiJ9.ZnVzZS5nb2xkLUFwcA.IWwdTWsFFJJj87KBFwodDfbKF2Hk9hqhn2C7JtFAwzM";
    const sendMail =async (context, data) => {
        return await axios.post(context.state.URL+'/users/send-mail', data)
        .then((res) => {
            if(res.data.status){
                return {status:1}
            }else{
                return {status:0}
            }
        })
    }
    
    const VToken = async (context, data) => {
        return await axios.post(context.state.URL+'/users/siteverify', data)
        .then((res) => {return res.data})
        .catch((error) => { return {status:0, error} })
    }
    
    const checkReferralID = async (context, data) => {
        return axios.post(context.state.URL+'/users/check-referral-id', {email:data.email})
        .then((res) => {
            console.log('checkInterest',res)
            return res.data
        })
        .catch((error) => {
            console.log(error)
            return {status:0}
        })

    }
    const checkReferral = async (context, data) => {
        return axios.post(context.state.URL+'/users/check-referral', {email:data.email})
        .then((res) => {
            console.log('checkInterest',res)
            return res.data
        })
        .catch((error) => {
            console.log(error)
            return {status:0}
        })

    }
    const addRegInterest = async (context, data) => {
        return axios.post(context.state.URL+'/users/add-prom-user', {user:data.user})
        .then((res) => {
            console.log('addRegInterest',res)
            return res.data
        })
        .catch((error) => {
            console.log(error)
            return {status:0}
        })

    }
    
    const sendQrCode = async (context, data) => {
        return await axios.post(context.state.URL+'/users/send-qrcode', data)
        .then((res) => {
            console.log('status of email qrcode', res.data)
            return res.data.status;
        })
        .catch((error) => {
            console.log(error);
            return 0
        })
    }
    
    const verifyEmailProm = async (context, id) => {
        return await axios.get(context.state.URL+'/users/verify-email-prom/'+id)
        .then((res) => {
            console.log('status of email verification', res.data)
            return res.data.status;
        })
        .catch((error) => {
            console.log(error);
            return 0
        })
    }
    
    const getPromUsers = async (context) => {
        // context.state.promUsers = [];
            // context.state.refCount = {};
            // context.state.totalReferred = 0;
            // context.state.refCountVerified = {};
            // context.state.totalReferredVerified = 0;
        return await axios.get(context.state.URL+'/users/get/prom-users').then((response) => {
            // axios.get(context.state.URL+'/orders/check-order', {params:{address}}).then((response) => {
            context.state.promUsers = response.data.data;
            context.state.refCount = response.data.refCount;
            context.state.totalReferred = response.data.totalReferred;
            context.state.refCountVerified = response.data.refCountVerified;
            context.state.totalReferredVerified = response.data.totalReferredVerified;
            // refCount, totalReferred,refCountVerified,totalReferredVerified
            return 1;
        }).catch(error => {
            if(error.response.status == 401) {
                console.log("got four o one error", error.response.data)
                context.dispatch('logout',error.response.data.error)
            }

            console.log(error)
            return 0;
        });
    }
    const getPromUsersPro = async (context) => {
        // setTimeout(
        //     async() => 
        //     {
            
                return await axios.get('https://fuse.gold/users/get/prom-users').then((response) => {
                    context.state.promUsers = response.data.data;
                    context.state.refCount = response.data.refCount;
                    context.state.totalReferred = response.data.totalReferred;
                    context.state.refCountVerified = response.data.refCountVerified;
                    context.state.totalReferredVerified = response.data.totalReferredVerified;
                    return 1;
                })    
            

            // }
            // , 1000000000);
        
        
    }
    const sendVerifEmail = async(context, data) => {
        return await axios.get(context.state.URL+'/users/send-prom-verification-email/'+data.email).then((res) => { return res.data})
    }
    const getNickName = async (context, data) => {
        return await axios.post(context.state.URL+'/users/get/nickname', data)
        .then((res) => {return res.data})
    }
    const updateUsernames = async (context, data) => {
        return await axios.post(context.state.URL+'/users/update-username', data)
        .then((res) => { console.log("response in store", res.data); return res.data})
    }
    const getRewardConfig = async (context) => {
        return await axios.get('https://fuseg-dev-app-u6wsh.ondigitalocean.app/admin/get/reward-config')
        .then((res) => { context.state.rewardConfig = res.data.data})
      }
      const getCachedData = async (context, data) => {
        return await axios.get(context.state.URL+'/users/get/cached-data/'+data.type)
        .then((res) => { 
            context.state.AppData = res.data.data
            console.log("AppData", context.state.AppData)
         })
      }  
    const saveCachedData = async(context, data) => {
        console.log("saving Cached Data")
        // return
        axios.post(context.state.URL+"/users/update/cache-data", data);
    }
    const getleaderboardData = async(context) => {
        console.log("dfsdfsdfsdfsdfsdfdsfdsf")
        axios.get(context.state.URL+'/users/get/total-regis')
        .then((res) => {
            console.log("response for LDATA", res.data)
            context.state.LDATA = res.data
            context.state.LDATA.rewards.data.TOTAL_POINTS = (context.state.LDATA.rewards.data.TOTAL_POINTS) ? context.state.LDATA.rewards.data.TOTAL_POINTS : 0
            context.state.LDATA.users.forEach(async(element, index) => {
            context.state.LDATA.users[index].allPoints = (context.state.LDATA.users[index].points + context.state.LDATA.users[index].nftPoints )
            });
            // context.state.LDATA.users.forEach(async(element, index) => {
            //     context.state.LDATA.users[index].number = (index+1)
            //     // context.state.LDATA.users[index].allPoints = (context.state.LDATA.users[index].points + context.state.LDATA.users[index].nftPoints )
            //     });
        })
    }
    const searchRank = async(context, data) => {
        // console.log("saving Cached Data")
        // return
        return await axios.get(context.state.URL+"/users/search/ranks/"+data.search)
        .then((res) => 
        { 
            console.log("response of searchRank", res.data);
             return res.data
        }
        )
    }
      
    
export {
    searchRank,
    getleaderboardData,
    getCachedData,VToken,
    saveCachedData,
    verifyEmailProm,
    getPromUsersPro,
    getRewardConfig,
    getPromUsers,
    updateUsernames,
    sendQrCode,
    getNickName,
    sendVerifEmail,
    checkReferralID,
    checkReferral,
    sendMail,
    addRegInterest
}